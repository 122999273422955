import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-cards";
import Loader from "./components/Loader/Loader";
import { Flights, Home, Layout, NoMatch, Orders, Tour } from "./routes/Routes";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <Layout />{" "}
          </Suspense>
        }
      >
        <Route
          index
          element={
            // <Suspense fallback={<Loader />}>
            <Home />
            // </Suspense>
          }
        />
        <Route
          path="tour"
          element={
            <Suspense fallback={<Loader />}>
              <Tour />
            </Suspense>
          }
        />
        <Route
          path="travel"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <Flights />
            </Suspense>
          }
        />
        {/* <Route path="hotels" element={<Hotels />} />
        <Route path="offers" element={<Offers />} /> */}
        <Route
          path="order/:id"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <Orders />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <NoMatch />
            </Suspense>
          }
        />
      </Route>
      {/* <Route
        path="/login"
        element={
          <Suspense fallback={<Loader />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/dashboard"
        element={
          <Suspense fallback={<Loader />}>
            <DashboardLayout />
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<Loader />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="/dashboard/orders"
          element={
            <Suspense fallback={<Loader />}>
              <DashboardOrders />
            </Suspense>
          }
        />
      </Route> */}
    </Routes>
  );
}

export default App;
