import { lazy } from "react";

export const Layout = lazy(() => import("../layout/Layout.jsx"));
export const DashboardLayout = lazy(() =>
  import("../pages/Dashboard/layout/Layout.js")
);
export const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard.jsx"));
export const Login = lazy(() => import("../pages/Login/Login.jsx"));
export const DashboardOrders = lazy(() =>
  import("../pages/Dashboard/Orders/Orders.jsx")
);
export const Home = lazy(() => import("../pages/Home/Home.jsx"));
export const Flights = lazy(() => import("../pages/flights/Flights.jsx"));
export const Tour = lazy(() => import("../pages/tour/Tour.jsx"));
export const Orders = lazy(() => import("../pages/orders/Orders.jsx"));
export const NoMatch = lazy(() => import("../pages/noMatch/NoMatch.jsx"));
